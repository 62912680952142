import * as React from "react";
import { navigate } from "gatsby";

// wanpass.me のアプリリンク廃止にともない、 map を app.wanpass.me にリダイレクトします。

// 例：
// https://wanpass.me/map?facility-id=4bfb25d2-54de-448d-b681-6876704546b7
// ->
// https://app.wanpass.me/map?facility-id=4bfb25d2-54de-448d-b681-6876704546b7
// にリダイレクト

const Page = () => {
  React.useEffect(() => {
    navigate("https://app.wanpass.me/map" + window.location.search);
  }, []);

  return <>Loading...</>;
};

export default Page;
